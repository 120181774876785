import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Container, Grid, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CreateProjectWindow from "./CreateProjectWindow";
import SearchFilterProjects from "./SearchFilterProjects";
import ProjectItem from "./ProjectItem";

function Projects({ checkedProjects, setCheckedProjects, projects, setProjects }) {
    const [searchedProjects, setSearchedProjects] = useState([]);
    const [openCreateProjectWindow, setOpenCreateProjectWindow] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteProjectId, setDeleteProjectId] = useState(null);
    const [loading, setLoading] = useState(true);


    const handleDeleteProject = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/project`, {
                method: "DELETE",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                mode: "cors",
                credentials: "include",
                body: `projectID=${id}`
            });
            const data = await response.json();
            setSearchedProjects(projects.filter(project => project.projectID !== id));
            setProjects(projects.filter(project => project.projectID !== id));
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        const fetchProjects = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/project?session_token=${Cookies.get("session_token")}`, {
                    method: "GET",
                    mode: "cors",
                    credentials: "include",
                });
                const data = await response.json();
                setProjects(data.projects);
                setSearchedProjects(data.projects);
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };
        fetchProjects();
    }, []);


    return (
        <>
            <SearchFilterProjects searchedProjects={searchedProjects} setSearchedProjects={setSearchedProjects} projects={projects} ></SearchFilterProjects>

            {/* Button for creating projects */}
            <div style={{ justifyItems: "center", justifyContent: "center", display: "flex", marginTop: "20px" }}>
                <Button variant="contained" onClick={() => setOpenCreateProjectWindow(true)} style={{ marginRight: "10px" }}>
                    Create New Project
                </Button>
            </div>

            {/* List of the projects */}
            <Container sx={{ marginTop: "2rem" }}>
                <Grid container spacing={2}>
                    {loading ? (
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (searchedProjects.length !== 0) ? searchedProjects.map(project => (
                        <Grid item xs={12} sm={6} md={4} key={project.projectID}>
                            <ProjectItem
                                project={project}
                                projectName={project.project_name}
                                projectId={project.projectID}
                                setDelete={setDeleteProjectId}
                                openDeleteDialog={setOpenDeleteDialog}
                                setCheckedProjects={setCheckedProjects}
                                checkedProjects={checkedProjects}
                            />
                        </Grid>
                    )) : (
                        <Grid item xs={12}>
                            <Typography variant="h6">No projects found</Typography>
                        </Grid>
                    )}
                </Grid>
            </Container>

            {/* Dialog when delete button is clicked */}
            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>
                    {"Confirm delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this project?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleDeleteProject(deleteProjectId);
                            setOpenDeleteDialog(false);
                        }}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog when create new project button is clicked */}
            <CreateProjectWindow
                open={openCreateProjectWindow}
                onClose={() => setOpenCreateProjectWindow(false)}
                updateSearchedProjects={setSearchedProjects}
                updateProjects={setProjects}
                projects={projects}
            />
        </>
    );
}

export default Projects;
