import { React, useState } from "react";
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import "../../style/data-input/CreatePhases.css"

function Phase({ phase, index, deletePhase, addAction, deleteAction }) {

    function handleDragOver(e) {
        e.preventDefault();
        if (e.dataTransfer.types.includes("draggable")) {
            e.dataTransfer.dropEffect = "move";
        } else {
            e.dataTransfer.dropEffect = "none";
        }
    }

    function handleDrop(e) {
        e.preventDefault();
        if (e.dataTransfer.getData("actionId") && e.dataTransfer.getData("actionName")) {
            const newAction = {
                id: e.dataTransfer.getData("actionId"),
                name: e.dataTransfer.getData("actionName"),
            }
            addAction(phase.id, newAction);
        }
    }

    return (
        <div>
            <p><b>Phase Number: </b> {index+1} </p>
            <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className='phase'>
                {
                    phase.actions.length === 0 ? <span className="hint-text">Drag actions here</span> :
                    phase.actions.map((action, index) => (
                        <Chip
                            key={action.name}
                            label={action.name}
                            onDelete={() => deleteAction(phase.id, index)}
                        />
                    ))
                }
                <IconButton className='delete-button' type="button" value="Delete" onClick={() => deletePhase(phase.id)}>
                    <DeleteOutlineIcon fontSize="small"/>
                </IconButton>
            </div>
        </div>
    )
}

export {Phase}
