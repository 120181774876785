import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';

const Login = (props) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = (event) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            mode: 'cors',
            credentials: 'include',
            body: `login=${login}&password=${password}`
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                const status = data.login;
                const session_token = data.session_token;

                if (status === "success") {
                    setError('');
                    localStorage.setItem('isLoggedIn', true);
                    props.onLogin();
                    Cookies.set('session_token', session_token, { expires: new Date(Date.now() + 10 * 60 * 60 * 1000) });
                } else {
                    setError('Invalid username or password');
                }
            })
            .catch(error => console.error(error));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleLogin(event);
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div style={{ height: '30px', color: 'red' }}>{error}</div>
            <TextField
                id="login-input"
                label="Username"
                variant="outlined"
                value={login}
                onChange={(event) => setLogin(event.target.value)}
                sx={{ marginBottom: 2, marginTop: 2, width: '300px' }}
            />
            <TextField
                id="password-input"
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                sx={{ marginBottom: 2, width: '300px' }}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginBottom: 2, width: '300px' }}
            >
                Sign In
            </Button>
        </Box>
    );
};

export default Login;
