import { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Parameters } from './Parameters';
import Select from 'react-select';
import '../style/data-input/ManualInput.css';

function ManualInput(props) {
    const [selectedMovementType, setSelectedMovementType] = useState(null);
    const options = [
        { value: 'forklift', label: 'Forklift' },
        { value: 'trolley', label: 'Trolley' },
        { value: 'walk', label: 'Walk' },
        { value: 'other', label: 'Other' }
    ]

    function handleInput(param, value) {
        props.setParameters({ ...props.parameters, [param]: value })
    }

    function handleMovementTypeChange(selectedOption) {
        setSelectedMovementType(selectedOption);
        props.setParameters({ ...props.parameters, 'movement_type': selectedOption.value });
        console.log(selectedOption.value);
        console.log(props.parameters);
    }

    return (
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            {Parameters.map(p =>
                <Grid container item xs={12} key={p.key} spacing={1} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>{p.parameter}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        {p.parameter === 'Movement type' ? (
                            <Select
                                options={options}
                                value={selectedMovementType}
                                onChange={handleMovementTypeChange}
                            />
                        ) : (<TextField
                            fullWidth
                            variant="outlined"
                            onChange={e => handleInput(p.key, e.target.value)}
                            InputProps={{
                                sx: {
                                    height: '3vh',
                                    textAlign: 'center',
                                }
                            }}
                        />)
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2">{p.unit}</Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )


}

export { ManualInput };
