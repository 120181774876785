import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './style/global.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';


const root = ReactDOM.createRoot(document.getElementById('root'));

/*
if (process.env.REACT_APP_DEV === 'true') {
  root.render(
      <App ws = {new WebSocket(process.env.REACT_APP_WEBSOCKET_CONNECTION)}/>
  );
} else {
  root.render(
      <App ws = {new WebSocket(`${config.server_connection.protocol}://${config.server_connection.address}:${config.server_connection.port}/${config.server_connection.route}/`)}/>
  );
}
*/

root.render(
  <App/>
);

reportWebVitals();
