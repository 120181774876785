import { Button, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

function ProjectItem(props) {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.checkedProjects.some(project => project.projectID === props.projectId)) {
            setIsChecked(true);
        }
    }, []);


    const handleCheckBox = () => {
        console.log(props.checkedProjects);
        setIsChecked(!isChecked);
        let checked = !isChecked;
        if (checked) {
            props.setCheckedProjects([...props.checkedProjects, props.project]);
        } else {
            const withoutUncheckedProjects = props.checkedProjects.filter(project => project.projectID !== props.projectId);
            props.setCheckedProjects(withoutUncheckedProjects);
        }
    }


    return (
        <div id="projectCard" style={{ backgroundColor: "whitesmoke", boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)", padding: "10px", textAlign: "center" }}>
            <p><b>{props.projectName}</b></p>
            <Button
                onClick={() => navigate(`/project/${props.projectId}`)}
            >
                Open Project
            </Button>
            <Button
                onClick={() => {
                    props.setDelete(props.projectId);
                    props.openDeleteDialog(true);
                }}
                style={{ color: "red" }}
            >
                Delete
            </Button>
            <Checkbox
                name={props.projectName}
                checked={isChecked}
                onChange={handleCheckBox}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </div>
    )
}

export default ProjectItem;