import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style/App.css';
import Cookies from 'js-cookie';
import Projects from './projects/Projects';
import Project from './projects/Project';
import Login from './modules/Login';
import KpiComparison from './projects/KpiComparison';
import MenuBar from './projects/MenuBar';


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [checkedProjects, setCheckedProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [kpisUpdated, setKpisUpdated] = useState(false);
    const [existingKpis, setExistingKpis] = useState([]);

    useEffect(() => {
        const verifySession = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/session?session_token=${Cookies.get('session_token')}`, {
                    method: 'GET',
                    mode: 'cors',
                    credentials: 'include',
                });
                const data = await response.json();
                if (data.status === "success") {
                    setIsLoggedIn(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        verifySession();
    }, []);


    // Fetching KPIs from the database.
    useEffect(() => {
        fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/kpis?session_token=${Cookies.get("session_token")}`, {
            method: "GET",
            mode: "cors",
            credentials: "include",
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const parsedKpis = data.params.map(element => ({ ...element, kpis_data: JSON.parse(element.kpis_data) }));
                setExistingKpis(parsedKpis);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [kpisUpdated]);


    if (!isLoggedIn) {
        return <Login onLogin={() => setIsLoggedIn(true)} />;
    }

    return (
        <Router>
            <MenuBar setIsLoggedIn={setIsLoggedIn} />
            <Routes>
                <Route exact path="/" element={<Projects checkedProjects={checkedProjects} setCheckedProjects={setCheckedProjects} projects={projects} setProjects={setProjects} />} />
                <Route exact path="/project/:id" element={<Project existingKpis={existingKpis} setKpisUpdated={setKpisUpdated} kpisUpdated={kpisUpdated} />} />
                <Route exact path='/kpi-comparison' element={<KpiComparison checkedProjects={checkedProjects} existingKpis={existingKpis} />} />
            </Routes>
        </Router>

    );
}



export default App;
