import React, { useState, useEffect, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Container } from '@mui/material';

import "../../style/data-input/CreateActions.css";

import { ItemsList } from "./ItemsList";
import { ActionsList } from './ActionsList';
import ItemFilter from './ItemFilter';
import ItemSearch from './ItemSearch';


function CreateActions(props) {
    const [items, setItems] = useState([]);
    const [searchedItems, setSearchedItems] = useState([]);
    const { selectedValues, setSelectedValues } = props;

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        marginBottom: "2rem"
    };

    const parameters = ["Machine", "Storage", "ForkliftMovement", "loadtime", "unloadtime", "actiontime", "processtime", "electricitycomsumption", "transportdistance"];

    const parseItems = useCallback(() => {
        const data = props.projectData.data.layers["layer-1"].items;
        const keys = Object.keys(data);

        let items = [];

        keys.forEach(item => {
            const newItem = {
                id: items.length,
                name: data[item].name,
                properties: data[item].properties,
                type: data[item].type
            };
            items.push(newItem);
        });

        localStorage.setItem("items", JSON.stringify(items));
        setItems(items);
        setSearchedItems(items);
    }, [props.projectData.data]);

    useEffect(() => {
        parseItems();
    }, [parseItems]);

    function handleSelect(event) {
        setSelectedValues(event.target.value);
    }

    function filterItems() {
        if (selectedValues.length === 0) {
            return searchedItems;
        } else {
            return searchedItems.filter(item => {
                return selectedValues.some(val => {
                    return Object.keys(item.properties).includes(val) || item.type === val;
                });
            });
        }
    }

    return (
        <div className="create-action">
            <Container disableGutters>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={1} justifyContent='center' alignItems='center'>
                            <Grid item xs={12} md={8}>
                                <ItemSearch searchedItems={searchedItems} setSearchedItems={setSearchedItems} items={items}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ItemFilter parameters={parameters} selectedValues={selectedValues} handleSelect={handleSelect} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {(items.length !== 0) ? <ItemsList items={filterItems()}></ItemsList> : <CircularProgress/>}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <ActionsList actions={props.actions} setActions={props.setActions} handleDeleteAction={props.handleDeleteAction}></ActionsList>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export {CreateActions}
