import React, { useState } from "react";
import ReactApexChart from 'react-apexcharts';
import 'bootstrap/dist/css/bootstrap.min.css';

function BottleNeckChart(props) {

    // if (props.data === undefined) {
    //     return(
    //         <p>No data available</p>
    //     )
    // }

    let categories = [];
    let series = [{name: "Batch Size", data:[]}]

    props.data.critical_path_actions.forEach(action => {
        categories.push(action.name);
        series[0]["data"].push(action.batch_size)
    });

    const [state, setState] = useState({
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        title: {
            text: 'Bottleneck critical path',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return Math.round(value); // Format to two decimal places
                }
            }
        }
    })

    return (
        <ReactApexChart options={state} series={series} type="line" height={350} />
    )
}

export {BottleNeckChart}