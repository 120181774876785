import Item from "./Item"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function ItemsList(props) {

    return (
        <List>
            {
                props.items.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <Item item={item} />
                    </ListItem>
                ))
            }
        </List>
    )
}

export {ItemsList}