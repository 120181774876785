const Parameters = [
    {
        parameter: "Required amount of pieces",
        effect_area: "All",
        unit: "pcs",
        kpi_impact: "",
        necessity: "Essential",
        key: "required_amount_of_pieces"
    },
    {
        parameter: "Batch size (handled pcs at time)",
        effect_area: "All",
        unit: "pcs",
        kpi_impact: "",
        necessity: "Essential",
        key: "batch_size"
    },
    {
        parameter: "Cost of workhour",
        effect_area: "KPIs",
        unit: "€ per h/pcs",
        kpi_impact: "",
        necessity: "Essential",
        key: "cost_of_workhour"
    },
    {
        parameter: "Categorization of items (size/difficulty/weight…)",
        effect_area: "All",
        unit: "A/B/C",
        kpi_impact: "",
        necessity: "Optional",
        key: "categorization_of_items"
    },
    {
        parameter: "Number of different parts per product",
        effect_area: "pcs",
        unit: "number",
        kpi_impact: "",
        necessity: "Optional",
        key: "number_of_different_parts_per_product"
    },
    {
        parameter: "Raw-part parallelity",
        effect_area: "",
        unit: "",
        kpi_impact: "",
        necessity: "Optional",
        key: "raw_part_parallelitys"
    },
    {
        parameter: "Raw material amount",
        effect_area: "",
        unit: "",
        kpi_impact: "",
        necessity: "Optional",
        key: "raw_material_amount"
    },
    {
        parameter: "Movement type",
        effect_area: "Movements",
        unit: "",
        kpi_impact: "",
        necessity: "Essential",
        key: "movement_type"
    },
    {
        parameter: "Movement type speeds",
        effect_area: "Movements",
        unit: "m/s",
        kpi_impact: "",
        necessity: "Essential",
        key: "movement_type_speeds"
    },
    {
        parameter: "Ram material types",
        effect_area: "pcs",
        unit: "material",
        kpi_impact: "CO2",
        necessity: "Optional",
        key: "ram_material_types"
    },
    {
        parameter: "Worktime effectivity factor",
        effect_area: "Manual worktime",
        unit: "Factor",
        kpi_impact: "",
        necessity: "Optional",
        key: "worktime_effectivity_factor"
    },
    {
        parameter: "General energy consumtion/required for enviroments",
        effect_area: "",
        unit: "",
        kpi_impact: "",
        necessity: "",
        key: "general_energy_consumtion_required_for_enviroments"
    },
]

export {Parameters};