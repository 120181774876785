import React from "react";
import { Box, Typography } from '@mui/material';

function TimePerProduct(props) {
    if (props.data === undefined) {
        return (
            <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
                <Typography variant="body1"><b>Time per Product (s/prdct):</b></Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
            <Typography variant="body1"><b>Time per Product (s/prdct):</b> {props.data?.time_per_product?.toFixed(2) ?? '-'}</Typography>
        </Box>
    );
}

export {TimePerProduct};
