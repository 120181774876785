import { Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useEffect, useState } from "react";

function KpiComparison({ checkedProjects, existingKpis }) {
    const [projectsWithKpis, setProjectsWithKpis] = useState([]);


    useEffect(() => {
        const uniqueProjects = new Map();
        checkedProjects.map(checkedProject => {
            const foundProject = existingKpis.find(project => project.id === checkedProject.projectID);
            if (foundProject) {
                uniqueProjects.set(foundProject.project_name, foundProject);
                return foundProject;
            } else {
                uniqueProjects.set(checkedProject.project_name, checkedProject);
                return checkedProject;
            }
        });
        setProjectsWithKpis(Array.from(uniqueProjects.values()));
    }, []);


    return (
        <>
            <p style={{ color: "gray", marginTop: "3rem", display: "flex", justifyContent: "center" }}>
                To see KPIs in the comparison table, open project of interest, set Actions and Phases you are interested in and click "Calculate".Go back, tick projects to see them in KPI comparison table.
            </p>
            {/* Table with project names and KPIs*/}
            <TableContainer component={Paper} sx={{ margin: "0 auto", marginBottom: "3rem", width: "max-content" }}>
                <Table aria-label="project-table" style={{ borderCollapse: "collapse" }} >
                    {/* Column titles */}
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bold", border: "1px solid lightgray", textAlign: "center" }}>KPIs</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ fontWeight: "bold", border: "1px solid lightgray", textAlign: "center", width: "200px" }}>{project.project_name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Rows */}
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Critical path time (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.cycle_time?.critical_path_time?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Critical path actions</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>
                                    {project.kpis_data?.cycle_time?.critical_path_actions?.map((action, i, arr) => `${i === arr.length - 1 ? action.name : action.name + " ➝ "}`) || "-"}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Total action time (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.summation_of_times?.total_actiontime?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Total load time (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.summation_of_times?.total_loadtime?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Total unload time (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.summation_of_times?.total_unloadtime?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Total process time (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.summation_of_times?.total_worktime?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Bottleneck action</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.bottleneck?.bottleneck?.name || "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Bottleneck capacity (pcs/hour)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{Number.isNaN(project.kpis_data?.bottleneck?.bottleneck?.batch_size * project.kpis_data?.bottleneck?.bottleneck?.actiontime / 3600) ? "-" : (project.kpis_data?.bottleneck?.bottleneck?.batch_size * project.kpis_data?.bottleneck?.bottleneck?.actiontime / 3600).toFixed(2)}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Bottleneck action time (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.bottleneck?.bottleneck?.actiontime?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Time per product (s)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.time_per_product?.time_per_product?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: "0.5px solid lightgray" }}>Output (product/hour)</TableCell>
                            {projectsWithKpis?.map((project, index) => (
                                <TableCell key={index} style={{ border: "0.5px solid lightgray" }}>{project.kpis_data?.output?.output?.toFixed(2) ?? "-"}</TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default KpiComparison;