const config = {
    server_connection: {
        protocol: 'wss',
        address: 'leapp.hiava.fi',
        port: '4000',
        route: 'websocket'
    }
}

module.exports = config;
