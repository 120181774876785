import { TextField, IconButton, Paper, InputBase, MenuItem } from "@mui/material";
import React, { useState, useRef } from 'react';
import SearchIcon from "@mui/icons-material/Search";


const SearchFilterProjects = (props) => {
    const [sortOption, setSortOption] = useState('')
    const searchRef = useRef(null);
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        marginTop: '2rem'
    };

    const handleSearchChange = () => {
        if (searchRef.current.value !== "") {
            props.setSearchedProjects(props.projects.filter(project => project.project_name.toLowerCase().includes(searchRef.current.value.toLowerCase())))
        } else {
            props.setSearchedProjects(props.projects)
        }
    };

    const handleDropdownChange = (event) => {
        setSortOption(event.target.value);
        switch (event.target.value) {
            case "option1":
                props.setSearchedProjects([...props.searchedProjects].sort((a, b) => a.project_name.localeCompare(b.project_name)))
                break;
            case "option2":
                props.setSearchedProjects([...props.searchedProjects].sort((a, b) => b.project_name.localeCompare(a.project_name)))
                break;
            case "option3":
                props.setSearchedProjects([...props.searchedProjects].sort((a, b) => b.projectID - a.projectID))
                break;
            case "option4":
                props.setSearchedProjects([...props.searchedProjects].sort((a, b) => a.projectID - b.projectID))
                break;
            default:
                break;
        }
    };

    return (
        <div style={containerStyle}>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', height: "3.2rem", width: 400 }}
            >
                <InputBase
                    id="searchProject"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Projects"
                    inputRef={searchRef}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={handleSearchChange}
                />
                <IconButton disabled sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
            <TextField
                name="filterProjects"
                sx={{
                    minWidth: '8rem',
                    marginLeft: '1rem',
                    '& .MuiInputBase-root': {
                        height: '3.3rem',
                        alignItems: 'center',
                    },
                }}
                select
                label="Filter"
                variant="outlined"
                value={sortOption}
                onChange={handleDropdownChange}
            >
                <MenuItem value="option1">A-Z</MenuItem>
                <MenuItem value="option2">Z-A</MenuItem>
                <MenuItem value="option3">From latest to earliest</MenuItem>
                <MenuItem value="option4">From earliest to latest</MenuItem>
            </TextField>
        </div>
    );
}

export default SearchFilterProjects;