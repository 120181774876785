import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import React, { useState, useRef } from "react"

const ItemSearch = (props) => {
    const searchRef = useRef(null);

    const handleSearchChange = () => {
        if (searchRef.current.value !== "") {
            props.setSearchedItems(props.items.filter(item => item.name.toLowerCase().includes(searchRef.current.value.toLowerCase())))
        } else {
            props.setSearchedItems(props.items)
        }
      };

    return (
          <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: 'auto', height: '38px' }}
          >
            <InputBase
              sx={{ p: '10px', flex: 1 }}
              placeholder="Search Items"
              inputRef={searchRef}
              inputProps={{ 'aria-label': 'search google maps' }}
              onChange={handleSearchChange}
            />
          <IconButton disabled sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
    );
}

export default ItemSearch;