import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

function Item({ item }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    function handleDragStart(e, id, name) {
        e.dataTransfer.setData("itemId", id);
        e.dataTransfer.setData("itemName", name);
        e.dataTransfer.setData("draggable", "true");

        setTooltipOpen(false);
    }

    const selectedProperties = ["loadtime", "unloadtime", "actiontime", "processtime", "electricitycomsumption", "transportdistance", "capacity"];

    const tooltipContent = Object.entries(item.properties)
        .filter(([key, value]) => selectedProperties.includes(key))
        .map(([key, value], index) => (
            <React.Fragment key={index}>
                {`${key}: ${value.time || value.length || value} ${value.unit || ''}`}
                <br />
            </React.Fragment>
        ));

    return (
        <div id={item.id}>
            <Tooltip
                title={tooltipContent}
                placement="right"
                open={tooltipOpen}
                onClose={() => setTooltipOpen(false)}
                onOpen={() => setTooltipOpen(true)}
            >
                <Chip
                    draggable={true}
                    onDragStart={(e) => handleDragStart(e, item.id, item.name)}
                    key={item.name}
                    label={item.name}
                    sx={{
                        height: 'auto',
                        cursor: 'grab',
                        marginBottom: '1px',
                        '&:active': {
                            cursor: 'grabbing',
                        },
                        '& .MuiChip-label': {
                            display: 'block',
                            whiteSpace: 'normal',
                        },
                    }}
                />


            </Tooltip>
        </div>
    )
}

export default Item;
