import '../style/chart-output/Chart.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';

function CycleTimeChart(props) {

    // if (props.data === undefined) {
    //     return(
    //         <div>
    //           <p>No data available</p>
    //         </div>
    //     )
    // }

    let series = [{data: []}]

    props.data.periods.forEach(period => {

        period.forEach(action => {
          series[0].data.push(
              {
                  x: action.name,
                  y: [action.starttime, action.endtime],
                  fillColor: "#32a852"
              }
          )
        });

    });

    const [state, setState] = useState({
        chart: {
          height: 350,
          type: 'rangeBar'
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%',
          }
        },
        xaxis: {
          type: 'numeric'
        }
      })

    return (
        <ReactApexChart options={state} series={series} type="rangeBar" height={700} />
    )

}


export {CycleTimeChart};