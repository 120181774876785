import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
// import Chip from '@mui/material/Chip';
import EastIcon from '@mui/icons-material/East';
import '../style/metrics-output/Metrics.css'

function CycleTimeMetrics(props) {
  if (props.data === undefined) {
      return (
          <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
              <Typography variant="body1"><b>Critical Path Time:</b></Typography>
              <Typography variant="body1"><b>Critical Path Actions:</b></Typography>
          </Box>
      )
  }

  return (
      <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2 }} className="metrics">
          <Typography variant="body1"><b>Critical Path Time:</b> {props.data?.critical_path_time?.toFixed(2) ?? '-'} seconds </Typography>
          <Typography variant="body1"><b>Critical Path Actions:</b></Typography>
          <Grid container spacing={1} alignItems="center" paddingLeft="8px">
              {props.data?.critical_path_actions?.map((action, index, array) => (
                  <Grid item key={action.name} className='critical-path-item'>
                      <Chip label={action.name} />
                      {index < array.length - 1 && <EastIcon fontSize='medium' sx={{color: '#666666', marginLeft: '4px', marginRight: '4px'}}/>}
                  </Grid>
              )) ?? '-'}
          </Grid>
      </Box>
  );
}

export {CycleTimeMetrics};
