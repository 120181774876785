import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Input } from "../modules/Input"
import { Charts } from "../modules/Charts"
import { Metrics } from "../modules/Metrics"
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import '../style/Desktop.css'

const Project = ({ existingKpis, setKpisUpdated, kpisUpdated }) => {
    const { id } = useParams();
    const [socket, setSocket] = useState(null);
    const [projectData, setProjectData] = useState({});
    const [actions, setActions] = useState([]);
    const [order, setOrder] = useState([]);
    const [parameters, setParameters] = useState({});
    const [kpis, setKpis] = useState({})
    const kpisObjRef = useRef({});
    const countRef = useRef(0);
    const existingKpisRef = useRef([]);
    const projectName = useRef("");
    const navigate = useNavigate();


    // Fetching KPIs from the database.
    useEffect(() => {
        fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/kpis?session_token=${Cookies.get("session_token")}`, {
            method: "GET",
            mode: "cors",
            credentials: "include",
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const parsedKpis = data.params.map(element => ({ ...element, kpis_data: JSON.parse(element.kpis_data) }));
                existingKpisRef.current = [...parsedKpis];
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }, []);



    const saveKpisToDatabase = (id, kpi) => {
        // Check if the KPI with the same ID already exists and implement whether POST or PATCH.
        const exists = existingKpisRef.current.some(element => element.id === id);
        const method = exists ? 'PATCH' : 'POST';
        console.log("Method: " + method);
        fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/kpis`, {
            method: method,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `kpi_id=${id}&project_name=${projectName.current}&session_token=${Cookies.get('session_token')}&kpis_data=${kpi}`,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`Failed to ${exists ? 'update' : 'create'} KPIs`);
                }
            })
            .then(data => {
                console.log(exists ? 'KPIs are updated' : 'KPIs are created', data);
                if (method === 'POST') {
                    existingKpisRef.current = [...existingKpis];
                    existingKpisRef.current = [...existingKpisRef.current, { id: data.project.id, project_name: data.project.project_name, kpis_data: JSON.parse(data.project.kpis_data) }];
                }
                console.log(existingKpisRef.current);
                setKpisUpdated(!kpisUpdated);
            })
            .catch(error => {
                console.error(exists ? 'Error updating KPIs' : 'Error creating KPIs', error);
            });
    };


    // Fetching all projects from the database
    useEffect(() => {
        const fetchProject = () => {
            fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/project?session_token=${Cookies.get('session_token')}&projectID=${id}`, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to fetch project data.');
                    }
                })
                .then((data) => {
                    setProjectData(JSON.parse(data.project.project_data));
                    projectName.current = data.project.project_name;
                    setActions((data.project.actions == null) ? [] : JSON.parse(data.project.actions));
                    setOrder((data.project.phases == null) ? [] : JSON.parse(data.project.phases));
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        fetchProject();
        setSocket(new WebSocket(process.env.REACT_APP_WEBSOCKET_CONNECTION + `?token=${Cookies.get('session_token')}`))
    }, []);


    // Calculating KPIs
    useEffect(() => {
        if (socket) {
            socket.onmessage = function (event) {
                var data = {};
                try {
                    data = JSON.parse(event.data);
                } catch (error) {
                    data = {};
                    console.log(error);
                }
                if (data.values && (data.values.error === "Provided unsupported format" || data.values === "null")) {
                    console.log("Please choose another file, this one is not working right now.");
                } else {
                    setKpis((prevKpis) => ({ ...prevKpis, [data.function]: data.data }));
                    // Setting KPIs for comparison table
                    if (countRef.current === 5) {
                        countRef.current = 0;
                        kpisObjRef.current[data.function] = data.data;
                        saveKpisToDatabase(parseInt(id), JSON.stringify(kpisObjRef.current));
                        console.log("All KPIs calculated");
                        kpisObjRef.current = {};
                    } else {
                        countRef.current++;
                        kpisObjRef.current[data.function] = data.data;
                    }
                }
            };
        }
    }, [socket, setKpis]);


    const returnToProjects = () => {
        navigate("/");
    }


    function handleDeleteAction(id) {
        const newActions = actions.filter(action => action.id !== id);
        const newOrder = order.map(phase => {
            const filteredList = phase.actions.filter(action => action.id !== id);
            return { id: phase.id, actions: filteredList };
        });
        setActions(newActions);
        setOrder(newOrder);
    }

    function handleDeletePhase(id) {
        const newOrder = order.filter(phase => phase.id !== id);
        setOrder(newOrder);
    }

    if (projectData.data === undefined) {
        return (
            <div style={{ "position": "fixed", "top": "50%", "left": "50%", "transform": "translate(-50%, -50%)" }}>
                <Stack spacing={1} direction="column" alignItems="center">
                    <CircularProgress />
                    <Button onClick={() => returnToProjects()} variant="contained">Back to Projects</Button>
                </Stack>
            </div>
        )
    }

    return (
        <div className='desktop'>
            <Input
                ws={socket}
                projectID={id}
                projectData={projectData}
                parameters={parameters} setParameters={setParameters}
                order={order} setOrder={setOrder}
                actions={actions} setActions={setActions}
                handleDeleteAction={handleDeleteAction}
                handleDeletePhase={handleDeletePhase}
            />
            <Charts data={kpis} />
            <Metrics data={kpis} />
        </div>
    )
}

export default Project;