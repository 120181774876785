import { React, useState } from "react";
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import "../../style/data-input/CreateActions.css"

function Action({ action, deleteAction, addItem, deleteItem }) {

    function handleDragOver(e) {
        e.preventDefault();
        if (e.dataTransfer.types.includes("draggable")) {
            e.dataTransfer.dropEffect = "move";
        } else {
            e.dataTransfer.dropEffect = "none";
        }
    }

    function handleDrop(e) {
        e.preventDefault();
        if (e.dataTransfer.getData("itemId") && e.dataTransfer.getData("itemName")){
            const newItem = {
                id: e.dataTransfer.getData("itemId"),
                name: e.dataTransfer.getData("itemName"),
            }
            addItem(action.id, newItem)
        }
    }

    return (
        <div>
            <p><b>Action name: </b>{action.name}</p>
            <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className='action'>
                {
                    action.items.length === 0 ? <span className="hint-text">Drag items here</span> :
                    action.items.map((item, index) => (
                        <Chip
                            key={item.name}
                            label={item.name}
                            onDelete={() => deleteItem(action.id, index)}
                        />
                    ))
                }
                <IconButton className='delete-button' onClick={() => deleteAction(action.id)} disableRipple>
                    <DeleteOutlineIcon fontSize="small"/>
                </IconButton>
            </div>
        </div>
    )
}

export {Action}
