import { ControlPanel } from "../input/ControlPanel"
import { FlowManager } from "../input/FlowManager"
import "../style/Desktop.css"

function Input(props) {

    return (
        <div className="input">
            <FlowManager projectData={props.projectData}
                parameters={props.parameters} setParameters={props.setParameters}
                order={props.order} setOrder={props.setOrder}
                actions={props.actions} setActions={props.setActions}
                handleDeleteAction={props.handleDeleteAction}
                handleDeletePhase={props.handleDeletePhase}>
            </FlowManager>

            <ControlPanel order={props.order} actions={props.actions} parameters={props.parameters} ws={props.ws} projectID={props.projectID} projectData={props.projectData}></ControlPanel>
        </div>
    )
}

export { Input }