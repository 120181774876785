import React, { useState } from 'react';
import { ManualInput } from './ManualInput';
import { CreateActions } from "./workflow/CreateActions"
import { CreateOrder } from "./workflow/CreateOrder"

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


function FlowManager(props) {

    const [value, setValue] = React.useState(0);
    const [selectedValues, setSelectedValues] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Parameters" {...a11yProps(0)} disableRipple />
                <Tab label="Actions" {...a11yProps(1)} disableRipple />
                <Tab label="Order" {...a11yProps(2)} disableRipple />
            </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{ overflowY: 'auto',  height: "75vh"}}>
              <ManualInput
                parameters={props.parameters}
                setParameters={props.setParameters}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
              />
            </TabPanel>
            <TabPanel value={value} index={1} style={{ overflowY: 'auto',  height: "75vh"}}>
              <CreateActions
                projectData={props.projectData}
                actions={props.actions}
                setActions={props.setActions}
                handleDeleteAction={props.handleDeleteAction}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
              />
            </TabPanel>
            <TabPanel value={value} index={2} style={{ overflowY: 'auto',  height: "75vh"}}>
              <CreateOrder
                order={props.order}
                setOrder={props.setOrder}
                actions={props.actions}
                setActions={props.setActions}
                handleDeletePhase={props.handleDeletePhase}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
              />
            </TabPanel>
        </Box>
    );

}

export {FlowManager}
