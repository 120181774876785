import React from 'react';
import { Box, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';

function BottleNeckMetric(props) {
    if (props.data === undefined) {
        return(
            <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
                <Typography variant="body1"><b>Bottleneck Action:</b></Typography>
                <Typography variant="body1"><b>Bootleneck Capacity (pcs/hour):</b></Typography>
                <Typography variant="body1"><b>Bootleneck Action Time:</b></Typography>
            </Box>
        )
    }

    return(
        <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
            <Typography variant="body1"><b>Bottleneck Action:</b> <Chip label={props.data?.bottleneck?.name ?? '-'} color="warning" /> </Typography>
            <Typography variant="body1"><b>Bootleneck Capacity (pcs/hour):</b> {props.data?.bottleneck?.batch_size?.toFixed(2) ?? '-'}</Typography>
            <Typography variant="body1"><b>Bootleneck Action Time:</b> {props.data?.bottleneck?.actiontime?.toFixed(2) ?? '-'}</Typography>
        </Box>
    )
}

export {BottleNeckMetric};
