import React, { useState } from 'react';
import NAMES from '../metrics/KPINames';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Box, Snackbar, Alert } from '@mui/material';

function ControlPanel(props) {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const navigate = useNavigate();

    function requestCalculation() {
        if (props.data === null) {
            alert("Choose a file");
            return;
        }

        const data = props.projectData;
        const order = props.order;
        const actions = props.actions;
        const parameters = props.parameters

        Object.assign(data, { actions: actions });
        Object.assign(data, { order: order });
        Object.assign(data, { parameters: parameters });

        props.ws.send(
            JSON.stringify({
                analytics: true,
                names: NAMES,
                data: data,
            })
        )
    }

    const saveProject = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/project`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `session_token=${Cookies.get('session_token')}&projectID=${props.projectID}&actions=${JSON.stringify(props.actions)}&phases=${JSON.stringify(props.order)}`,
            });
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setOpenSnackbar(true);
            } else {
                throw new Error('Update project Failed.');
            }
        } catch (error) {
            console.error(error);
        }
    }

    const returnToProjects = () => {
        navigate("/")
    }

    return (
        <>
            <Grid
                justifyContent="center"
                alignItems="center"
                sx={{ borderTop: '1px solid black' }}
            >
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button
                            disableRipple
                            variant="contained"
                            color="success"
                            onClick={() => saveProject()}
                            sx={{
                                marginTop: 2,
                                width: 155,
                                height: 35,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'none',
                                borderRadius: 2,
                                boxShadow: 'none',
                                '&:hover': {
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            Save Project
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button
                            disableRipple
                            variant="contained"
                            color="primary"
                            onClick={() => requestCalculation()}
                            sx={{
                                marginTop: 2,
                                width: 155,
                                height: 35,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'none',
                                borderRadius: 2,
                                boxShadow: 'none',
                                '&:hover': {
                                    boxShadow: 'none'
                                }
                            }}
                        >
                            Calculate
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button
                            disableRipple
                            variant="outlined"
                            onClick={() => returnToProjects()}
                            sx={{
                                marginTop: 2,
                                width: 155,
                                height: 35,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'none',
                                borderRadius: 2,
                                borderColor: 'gray',
                                color: 'gray',
                                '&:hover': {
                                    backgroundColor: 'rgba(128, 128, 128, 0.1)',
                                    borderColor: 'gray',
                                }
                            }}
                        >
                            Back to Projects
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Saved successfully!
                </Alert>
            </Snackbar>
        </>
    );
}


export { ControlPanel };
