import React, { useState } from "react";
import { Container, Grid } from "@mui/material";

import "../../style/data-input/CreatePhases.css"

import { PhasesList } from "./PhasesList"
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function Action({ action }) {
    function handleDragStart(e, id, name) {
        e.dataTransfer.setData("actionId", id);
        e.dataTransfer.setData("actionName", name);
        e.dataTransfer.setData("draggable", "true");
    }

    return (
        <div draggable={true} onDragStart={(e) => handleDragStart(e, action.id, action.name)} id={action.id}>
            <Chip
                key={action.name}
                label={action.name}
                sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                    },
                }}
            />
        </div>
    )
}

function ActionsList(props) {
    return (
        <div>
            <List>
                {
                    props.actions.map((action, index) => (
                        <ListItem disablePadding>
                            <Action action={action}></Action>
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}

function CreateOrder(props) {
    return (
        <div className="create-order">
            <Container disableGutters>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <ActionsList actions={props.actions} ></ActionsList>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <PhasesList order={props.order} setOrder={props.setOrder} handleDeletePhase={props.handleDeletePhase}></PhasesList>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export {CreateOrder}
