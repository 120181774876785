import React, { useState } from "react";
import NAMES from "../metrics/KPINames";
import { CycleTimeMetrics } from '../metrics/CycleTimeMetrics';
import { SummationOfTimesMetrics } from '../metrics/SummationOfTimesMetric';
import { BottleNeckMetric } from '../metrics/BottleneckMetric';
import { TimePerProduct } from '../metrics/TimePerProductMetrics';
import { OutputMetrics } from '../metrics/OutputMetrics'
import { Box, Button, Typography, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Metrics(props) {
    const [open, setOpen] = useState({});

    const toggleOpen = (id) => {
        setOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
    }

    function kpiRender(kpiName) {
        switch (kpiName) {
            case "cycle_time":
                return <CycleTimeMetrics data={props.data.cycle_time} />;
            case "summation_of_times":
                return <SummationOfTimesMetrics data={props.data.summation_of_times} />;
            case "bottleneck":
                return <BottleNeckMetric data={props.data.bottleneck} />;
            case "time_per_product":
                return <TimePerProduct data={props.data.time_per_product} />;
            case "output":
                return <OutputMetrics data={props.data.output} />;
            default:
                return;
        }
    }

    return (
        <Box sx={{
            borderLeft: '1px solid black',
            bgcolor: 'white',
        }}>
            {Object.keys(NAMES).map(kpi =>
                <Box key={kpi} sx={{
                    border: 1,
                    borderColor: '#ccc',
                    borderRadius: 2,
                    padding: 2,
                    margin: 1
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography variant="h7" sx={{ paddingBottom: '1px' }}><b>{NAMES[kpi]}</b></Typography>
                        <Button
                            sx={{ borderRadius: 2, maxWidth: '36px', maxHeight: '36px', minWidth: '36px', minHeight: '36px' }}
                            variant="outlined" disableRipple onClick={() => toggleOpen(kpi)}>
                            {open[kpi] ? <ExpandLessIcon /> : <ExpandMoreIcon fontSize="medium" />}
                        </Button>
                    </Box>
                    <Collapse in={open[kpi]} >
                        <Box sx={{ paddingTop: 2 }}>
                            {kpiRender(kpi)}
                        </Box>
                    </Collapse>
                </Box>
            )}
        </Box>
    );
}

export { Metrics };
