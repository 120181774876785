import { Phase } from "./Phase"
import AddIcon from '@mui/icons-material/Add';

function PhasesList(props) {

    function handleAddPhase(e) {
        const newPhase = {
            actions: []
        }
        props.setOrder(prevorder => {
            return [...prevorder, {id: ((props.order.length === 0) ? 0 : props.order[props.order.length-1].id + 1), actions: newPhase.actions}]
        })
    }

    function handleAddAction(phaseId, action) {
        let currentOrder = props.order;
        const phase = currentOrder.findIndex(p => p.id === phaseId)
        currentOrder[phase].actions.push(action);
        props.setOrder([...currentOrder]);
    }

    function handleDeleteAction(phaseId, actionIndex) {
        let currentOrder = props.order;
        const phase = currentOrder.findIndex(p => p.id === phaseId)
        currentOrder[phase].actions.splice(actionIndex, 1)
        props.setOrder([...currentOrder]);
    }

    return (
        <div>
            {
                props.order.map((phase, index) => (
                    <Phase phase={phase} index={index} key={phase.id} deletePhase={props.handleDeletePhase} addAction={handleAddAction} deleteAction={handleDeleteAction} ></Phase>
                ))
            }

            <div class="add-phase">
                <button onClick={handleAddPhase}><AddIcon fontSize="xs"/></button>
            </div>

        </div>
    )
}

export {PhasesList}