import React from 'react';
import { Box, Typography } from '@mui/material';

function OutputMetrics(props) {
    if (props.data === undefined) {
        return (
            <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
                <Typography variant="body1"><b>Output (prdct/h):</b></Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
            <Typography variant="body1"><b>Output (prdct/h):</b> {props.data?.output?.toFixed(2) ?? "-"}</Typography>
        </Box>
    )
}

export {OutputMetrics};
