import React from "react";
import '../style/chart-output/Chart.css'
import { CycleTimeChart } from "../charts/CycleTimeChart";
import { SummationOfTimesChart } from "../charts/SummationOfTimesChart";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { BottleNeckChart } from "../charts/BottleNeckChart";

function Charts(props) {

    if (Object.keys(props.data).length === 0 || props.data.cycle_time === undefined || props.data.summation_of_times === undefined || props.data.bottleneck === undefined) {
        return (
            <div className='no-project-data'>
                <p>No data available</p>
            </div>
        )
    }

    return (
        <div style={{ overflowY: 'scroll', height: '100vh' }} className="chart-output">
            <h1>Cycle Time</h1>
            <CycleTimeChart data={props.data.cycle_time} ></CycleTimeChart>
            <h1>Summation of Times</h1>
            <SummationOfTimesChart data={props.data.summation_of_times} ></SummationOfTimesChart>
            <h1>Bottleneck</h1>
            <BottleNeckChart data={props.data.bottleneck} ></BottleNeckChart>
        </div>
    )

}


export { Charts };