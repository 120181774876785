import React, { useState, useRef } from "react"
import { Action } from "./Action"
import AddIcon from '@mui/icons-material/Add';

import "../../style/data-input/CreateActions.css"

function ActionsList(props) {

    const actionNameRef = useRef("");

    function handleAddAction(e) {
        if (actionNameRef.current.value === "") return;
        const newAction = {
            name: actionNameRef.current.value,
            items: []
        }
        props.setActions(prevactions => {
            return [...prevactions, {id: ((props.actions.length === 0) ? 0 : props.actions[props.actions.length-1].id + 1), name: newAction.name, items: newAction.items}]
        })
        actionNameRef.current.value = "";
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleAddAction();
            actionNameRef.current.blur();
        }
    }

    function handleAddItem(actionId, item) {
        let currentActions = props.actions;
        const action = props.actions.findIndex(a => a.id === actionId)
        currentActions[action].items.push(item);
        props.setActions([...currentActions]);
    }

    function handleDeleteItem(actionId, itemIndex) {
        let currentActions = props.actions;
        const action = props.actions.findIndex(a => a.id === actionId)
        currentActions[action].items.splice(itemIndex, 1)
        props.setActions([...currentActions]);
    }

    return (
        <div>
            {
                props.actions.map((action, index) => (
                    <Action action={action} key={action.name} deleteAction={props.handleDeleteAction} addItem={handleAddItem} deleteItem={handleDeleteItem} ></Action>
                ))
            }

            <div className="add-action">
                <input ref={actionNameRef} type="text" placeholder="Enter name" className="input-action" onKeyDown={handleKeyDown}/>
                <button onClick={handleAddAction}><AddIcon fontSize="xs"/></button>
            </div>

        </div>
    )
}

export {ActionsList}