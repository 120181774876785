import React, { useState, useEffect } from 'react';
import { Typography, Button, Modal, TextField, Box, Checkbox, FormControlLabel } from '@mui/material';
import Cookies from 'js-cookie';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

const CreateProjectWindow = ({ open, onClose, updateSearchedProjects, updateProjects, projects }) => {
    const [file, setFileData] = useState({});
    const [checked, setChecked] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [projectName, setProjectName] = useState("");

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        setUploadedFileName(file.name.split(".")[0]);
        reader.onload = (event) => {
            const content = event.target.result;
            setFileData({ data: JSON.parse(content) })
        };

        reader.readAsText(file);
    };

    const handleCreateNewProject = async () => {
        try {
            if (!projects.some(project => projectName.trim() === project.project_name)) {
                const response = await fetch(`${process.env.REACT_APP_REQUEST_HANDLER}hiava/project`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: `project_name=${projectName.trim()}&session_token=${Cookies.get('session_token')}&project_data=${JSON.stringify(file)}`,
                });
                if (response.ok) {
                    const data = await response.json();
                    updateSearchedProjects(prevprojects => {
                        return [...prevprojects, { projectID: data.project.projectID, project_name: data.project.project_name }]
                    })
                    updateProjects(prevprojects => {
                        return [...prevprojects, { projectID: data.project.projectID, project_name: data.project.project_name }]
                    })
                    setFileData({});
                    setUploadedFileName(null);
                    setProjectName("");
                } else {
                    throw new Error('Create new project Failed.');
                }
            } else {
                setProjectName("");
                alert("Project with such name already exists.");
            }
        } catch (error) {
            console.error(error);
        }

        onClose(false);
    }

    const handleCheckBox = (event) => {
        setChecked(event.target.checked);
        if (!event.target.checked) {
            setProjectName("");
        }
    }

    useEffect(() => {
        if (checked && uploadedFileName) {
            setProjectName(uploadedFileName);
        }
    }, [checked, uploadedFileName]);


    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom="1rem">
                        Create project
                    </Typography>
                    <TextField
                        id="filled-basic"
                        label="Project name"
                        variant="filled"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                    <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckBox} inputProps={{ 'aria-label': 'controlled' }} />} label="Use name from file" />

                    <p></p>

                    <input type="file" id="file-input" style={{ display: "none" }} onChange={handleFileUpload} />
                    <Button onClick={() => document.getElementById('file-input').click()} variant="contained">Upload File</Button>

                    <p></p>

                    <Button variant="contained" onClick={handleCreateNewProject} >Create Project</Button>
                </Box>
            </Modal>
        </div>
    );
};

export default CreateProjectWindow;