import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const ItemFilter = ({ parameters, selectedValues, handleSelect }) => {
    return (
        <FormControl sx={{ width: '100%'}} size="small">
            <InputLabel id="filter-checkbox" sx={{justifyContent: 'center'}}>Filter</InputLabel>
            <Select
                labelId="filter-checkbox-label"
                id="filter-checkbox"
                multiple
                value={selectedValues}
                onChange={handleSelect}
                input={<OutlinedInput label="Filter" sx={{
                    '& .MuiInputBase-input': {
                        padding: '8x 14px',
                        height: 'auto',
                    },
                }}/>}
                renderValue={(selected) => (
                    <div style={{ whiteSpace: 'normal' }}>
                        {selected.join(', ')}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {parameters.map((parameter) => (
                    <MenuItem key={parameter} value={parameter}>
                        <Checkbox checked={selectedValues.indexOf(parameter) > -1} />
                        <ListItemText primary={parameter} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ItemFilter;
