import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import 'bootstrap/dist/css/bootstrap.min.css';

function SummationOfTimesChart(props) {

    // if (props.data === undefined) {
    //     return(
    //         <p>No data available</p>
    //     )
    // }

    const series = Object.values(props.data).slice(0,-1)

    const labels = []

    Object.keys(props.data).slice(0,-1).forEach(label => {
        labels.push(`${label.charAt(0).toUpperCase() + label.slice(1).split('_').join(' ')}`)
    });

    const [state, setState] = useState({
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: labels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    })

    return (
        <div id="chart">
            <ReactApexChart options={state} series={series} type="pie" width={500} />
        </div>
    )

}

export {SummationOfTimesChart}
