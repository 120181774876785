import React from 'react';
import { Box, Typography } from '@mui/material';

function SummationOfTimesMetrics(props) {
    if (props.data === undefined) {
        return (
            <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
                <Typography variant="body1"><b>Total Action Time:</b></Typography>
                <Typography variant="body1"><b>Total Load Time:</b></Typography>
                <Typography variant="body1"><b>Total Unload Time:</b></Typography>
                <Typography variant="body1"><b>Total Process Time:</b></Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ bgcolor: '#f5f5f5', border: '1px solid #ccc', borderRadius: 2, p: 2}}>
            <Typography variant="body1"><b>Total Action Time:</b> {props.data?.total_actiontime?.toFixed(2) ?? '-'} seconds</Typography>
            <Typography variant="body1"><b>Total Load Time:</b> {props.data?.total_loadtime?.toFixed(2) ?? '-'} seconds</Typography>
            <Typography variant="body1"><b>Total Unload Time:</b> {props.data?.total_unloadtime?.toFixed(2) ?? '-'} seconds</Typography>
            <Typography variant="body1"><b>Total Process Time:</b> {props.data?.total_worktime?.toFixed(2) ?? '-'} seconds</Typography>
        </Box>
    )
}

export {SummationOfTimesMetrics};
