import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';

export default function MenuBar({ setIsLoggedIn }) {
    const logOut = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
    }

    return (
        <>
            <AppBar position="static" sx={{backgroundColor: "rgb(40,40,40)"}}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Hiava Analytics
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <Link className='link' style={{ color: "white", marginRight: "25px" }} to='/'>Projects</Link>
                        <Link className='link' style={{ color: "white", marginLeft: "25px" }} to='/kpi-comparison'>KPIs</Link>
                    </div>
                    <Button color="inherit" onClick={logOut}>Log out</Button>
                </Toolbar>
            </AppBar>
        </>
    )
}